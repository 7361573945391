import axios from "axios";
import store from "@/store";
import {
  getToken
} from "@/utils/auth";

// 创建一个axios实例
const service = axios.create({
  baseURL: "/",
  timeout: 5000 // /请求超时
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 

    // if (store.getters.token) {
      // config.headers["Authorization"] = 'Bearer ' + getToken();
    // }
    return config;
  },
  error => {
    // /处理请求错误
    console.log(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    // console.log(res);
    // 如果自定义码不是200，则判断为错误。
    if (res.code == 0) {
       return res;
    } else {
      // uni.showToast({
      //   title: res.msg || "Error",
      //   icon: "error",
      //   duration: 5 * 1000
      // })
      return res.msg;
    }
  },
  error => {
    console.log("err" + error);
    // uni.showToast({
    //   title: error.msg,
    //   icon: "error",
    // })
    return Promise.reject(error);
  }
);

export default service;