import request from "@/utils/request";

export function indexIndex(data) {
  return request({
    url: "/index/index/index",
    method: "get",
    params:  data ,
  });
}
export function indexClick(data) {
  return request({
    url: "/index/index/click",
    method: "post",
    data,
  });
}
export function departList(data) {
  return request({
    url: "/index/index/departlist",
    method: "post",
    data,
  });
}