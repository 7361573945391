<template>
  <div class="mi-box">
    <div style="text-align: left;padding: 20px 20px 0 20px;">
      <img
        src="@/assets/image/logo.png"
        alt=""
      >
    </div>
    <div class="mi-dropdown">
      <div>
        <el-cascader
            v-model="DepartmentValue"
            placeholder="请选择部门"
            @change="handleClick"
            :options="DepartmentList"
            :clearable='true'
            :props="{value:'id', label: 'name', children: 'children',checkStrictly:true}"
        >
        </el-cascader>
      </div>
     <div>
       <el-select v-model="sidValue" placeholder="请选择">
         <el-option
             v-for="item in sidPtions"
             :key="item.value"
             :label="item.label"
             :value="item.value">
         </el-option>
       </el-select>
     </div>

    </div>
    <div
      class="infinite-list-wrapper"
      style=""
    >
      <div
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
        infinite-scroll-distance="1"
        infinite-scroll-immediate="true"
        class="video-data"
      >
        <div

          class="video-item"
          v-for="(item,index) in IndexList"
          :key="index"
          @click="clickItem(item.id)"
          v-show="sidValue==item.sid||sidValue=='全部'||sidValue==''"
        >

          <div class="video-audio">
            <audio
              :ref="'audio' + item.id"
              :src="'http://sound.0537ys.com'+item.thumbnail"
              controls
              controlsList="nodownload"
              oncontextmenu="return false"
              @play="handleAudioPlay(item.id,index)"
            >
            </audio>
          </div>
          <div>

            <el-button @click='handleAudioPlay(item.id,index)'>播放</el-button>
            <el-button @click='handleAudioPause(item.id)'>暂停</el-button>
          </div>
          <!-- <div class="video-item-hover"></div> -->

          <!-- <div class="video-img">
          <img
            :src="'http://sound.0537ys.com' + item.thumbnail"
            alt=""
          />
          <div
            v-if="item.id !== playingAudioId"
            class="video-play"
          >
            <img
              src="@/assets/image/play.png"
              alt=""
            />
          </div>
        </div> -->
          <div class="desc">
            <div class="desc-title">
               <el-tooltip
                class="item"
                effect="dark"
                :content="item.uid"
                placement="top"
              >
                <div class="desc-title-name">{{ item.name }}</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.dname"
                placement="top"
              >
                <div class="desc-title-department">部门：{{ item.dname}}</div>
              </el-tooltip>
             

            </div>
            <div class="bottom-info">
              <div class="create-time">
                <img src="@/assets/image/alarm-clock.png" />{{item.create_time}}
              </div>
              <div class="push-num">浏览了{{ item.click }}次</div>
            </div>
          </div>
        </div>
        <div class="video-position"></div>
        <div class="video-position"></div>
        <div class="video-position"></div>
        <div class="video-position"></div>
        <div class="video-position"></div>
        <div class="video-position"></div>
      </div>
    </div>
    <div
      v-if="loading"
      style="color:#fff"
    ><i class="el-icon-loading"></i>
      <div>拼命加载中...</div>
    </div>
    <div
      v-if="noMore"
      style="color:#fff"
    >没有更多了</div>
    <div style="height: 100px;"></div>
  </div>
</template>
<script>
import { indexIndex, indexClick, departList } from "@/api/user";
import router from "@/router";
export default {
  data () {
    return {
      playingAudioId: null,
      loading: false,
      IndexList: [],
      DepartmentValue: '',
      DepartmentList: [], // 部门列表
      page: 1,
      count: 0,
      sidPtions: [{
        value: '全部',
        label: '全部'
      }, {
        value: '已签单',
        label: '已签单'
      }, {
        value: '未签单',
        label: '未签单'
      }],
      sidValue: ''
    };
  },
  computed: {
    noMore () {
      return this.IndexList.length >= this.count
    },
    disabled () {
      return this.loading || this.noMore
    }
  },
  created () {
    this.getDepartList()
    this.getIndexList();

  },
  mounted () {
  },
  methods: {
    // 获取部门
    getDepartList () {
      departList().then((res) => {
        this.DepartmentList = res.list
        // console.log(this.DepartmentList);
      })
    },
    // 触底加载
    load () {
      // this.loading = true
      setTimeout(() => {
        this.page += 1
        this.getIndexList();
      }, 500)
    },
    // 获取音频列表
    getIndexList (type, index) {
      this.loading = true
      indexIndex({ page: this.page, limit: 25, did: this.DepartmentValue }).then(res => {
        if (res.sflog==1) {
          // this.$router.push('/myadmin')
          window.location.href ='http://sound.0537ys.com/myadmin'
        }else {
          
          if (type == 'audio') {
           
            this.IndexList[index].click++
          } else {
            this.count = res.count
            // this.IndexList = res.list;
            // this.IndexList = []
            this.IndexList.push(...res.list);
             
          }
          // console.log('IndexList',this.IndexList);
          this.loading = false
        }

      })
    },
    // 切换部门
    handleClick (e) {
      // console.log(e);
      if (e.length > 0) {
        this.DepartmentValue = e[e.length - 1]
      } else {
        this.DepartmentValue = ''
      }

      this.page = 1
      this.IndexList = []
      this.getIndexList();
      // console.log(this.DepartmentValue);
    },
    // 对应id
    clickItem (id) {
      // console.log(id);
      this.playingAudioId = id;
    },
    // 播放音频
    handleAudioPlay (audioId, index) {
      console.log(this.playingAudioId, audioId);
      if (this.playingAudioId !== audioId) {
        if (this.playingAudioId !== null) {
          const previousAudio = this.$refs['audio' + this.playingAudioId][0];
          if (previousAudio) {
            previousAudio.pause();
          }
        }
        this.playingAudioId = audioId;
        if (this.playingAudioId == audioId) {
          const previousAudio = this.$refs['audio' + this.playingAudioId][0];
          if (previousAudio) {
            previousAudio.play();
            indexClick({ id: audioId }).then(res => {
              console.log(index);
              this.getIndexList('audio', index);
            })
          }
        }
      } else if (this.playingAudioId == audioId) {
        const previousAudio = this.$refs['audio' + this.playingAudioId][0];
        if (previousAudio) {
          previousAudio.play();
          indexClick({ id: audioId }).then(res => {
            this.getIndexList('audio', index);
          })
        }
      }
    },
    // 暂停
    handleAudioPause (audioId) {
      console.log(this.playingAudioId, audioId);
      if (this.playingAudioId == audioId) {
        const previousAudio = this.$refs['audio' + this.playingAudioId][0];
        if (previousAudio) {
          previousAudio.pause();
        }
      }
      // this.playingAudioId = false;
    },

  },
  beforeDestroy () {
  },
};
</script>
<style lang="scss" scoped>
body {
  background-color: #131930;
}
#app {
  width: 97%;
  margin: 0 auto;
}
.mi-box {
  background-color: #131930;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: auto;
  .mi-head {
    margin-top: 10px;
    width: 100%;
    height: 50px;
  }
  .mi-dropdown {
    background-color: #2c3046;
    padding: 10px;
    border-radius: 8px;
    text-align: left;
    display: flex;
    .el-select {
      margin-left: 10px;
    }
  }
  .video-data {
    // padding: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    // height: 100%;
    // overflow: auto;
    .video-item {
      box-sizing: border-box;
      // height: 140px;
      width: 33%;
      margin-top: 20px;
      padding: 15px;
      background-color: #2c3046;
      border-radius: 8px;
      position: relative;

      cursor: pointer;
      .video-audio {
        margin-top: 0;
        // position: absolute;
        width: 100%;
        // top: 0;
        // left: 50%;
        // transform: translate(-50%, 0);
        z-index: 3;
        audio {
          width: 100%;
        }
      }
      .video-item-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
        display: none;
      }
      .img {
        display: flex;
        justify-content: center;
        width: 90%;
        margin: 0 auto;
        .shade {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          //border: 1rpx solid #000;
          background-color: #000;
          opacity: 0.4;
          z-index: 999;
        }
      }

      .video-img {
        display: flex;
        justify-content: center;
        width: 90%;
        height: 15%;
        margin: 0 auto;
        position: relative;
        img {
          height: 100%;
        }
        .video-play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;
          z-index: 2;
          img {
            width: 38px;
            height: 38px;
            opacity: 0.9;
          }
        }
      }
      .desc {
        width: 100%;
        // margin: 5% auto;
        .desc-title {
          display: flex;
          justify-content: space-between;
          color: #fff;
          margin: 5% 0;
          font-size: 16px;
          flex-direction: column;
          align-items: flex-start;
          .desc-title-department {
            color: #fff;
            text-align: left;
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .desc-title-name {
            // width: 20%;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // white-space: nowrap;
          }
        }

        .bottom-info {
          color: #999;
          display: flex;
          justify-content: space-between;
          .create-time {
            display: flex;
            align-items: center;
          }
          img {
            width: 18px;
          }
          .push-num {
            font-size: 13px;
            color: #999;
            display: flex;
            justify-content: space-between;
            //padding-bottom: 15px;
          }
        }
      }
    }
    .video-position {
      // height: 100px;
      width: 300px;
      margin-top: 20px;
      padding-top: 20px;
    }
    .video-item:hover .video-item-hover,
    .video-item:hover .video-play {
      display: block;
    }
  }
}
@media screen and (max-width: 615px) {
  .mi-box {
    .mi-dropdown {
      flex-direction: column;
      .el-select {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
  .video-item {
    width: 100% !important;
    height: 260px !important;
    padding: 9%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .desc {
      .desc-title {
        flex-direction: column;
        .desc-title-department {
          width: 100% !important;
        }
        .desc-title-name {
          width: 100% !important;
          text-align: left;
        }
      }
    }
  }


  .video-position {
    width: 150px !important;
  }

  .bottom-info {
    flex-direction: column;
    font-size: 12px;
  }
  .mi-head {
    margin-top: 0 !important;
  }

  .video-data {
    //padding: 0 !important;
    // justify-content: space-around !important;
  }
  .el-button {
    padding: 10px 15px;
  }
}
.el-cascader,.el-select {
  width: 300px;
}
</style>
